<template>
  <div>
    <div
      v-if="strapiData"
      class="page--cms"
    >
      <!-- Disabled for home -->
      <AppBreadcrumbs
        v-if="$route.path !== '/'"
        class="container"
      />

      <StrapiSlices
        v-if="strapiData?.Slices?.length"
        :slices="strapiData.Slices"
      />

      <!--  -->
      <!--  -->
    </div>

    <DevOnly v-else-if="strapiError">
      <pre>strapiError: {{ strapiError }}</pre>
    </DevOnly>

    <slot />
  </div>
</template>

<script lang="ts" setup>
const meta = inject('meta', ref())
const { strapiData, strapiError, getStrapiData } = useStrapiCms(meta?.value?.strapi_id, undefined, undefined, 'cmsPage')
const { t } = useI18n({ useScope: 'global' })

await getStrapiData()

const pageTitle = computed(
  () => strapiData.value?.PageTitle || '',
)

const metaTitle = computed(
  () => strapiData.value?.MetaData?.MetaTitle || pageTitle.value,
)

const metaDescription = computed(
  () => strapiData.value?.MetaData?.MetaDescription || pageTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs({
  label: pageTitle.value,
  parents: meta.value.parents,
})

const { pageView } = useDataLayer()

if (meta.value.label === '404') {
  pageView('404', metaTitle.value)
}
else {
  pageView('cms', metaTitle.value)
}

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: strapiData.value?.MetaData?.MetaRobots?.replace('_', ', ') },
  ],
  script: [
    { type: 'application/ld+json', hid: 'RichSnippets', innerHTML: strapiData.value?.MetaData?.RichSnippets || '' },
  ],
})

const route = useRoute()
const { canonical } = useSeo()
canonical(computed(() => route.path))
</script>

<style src="~layers/app/pages/~cms.css" />
